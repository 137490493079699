@font-face {
    font-family: 'Fira Code';
    font-style:  normal;
    font-weight: 400;
    src: url("/fonts/FiraCode-Regular.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Fira Code';
    font-style:  normal;
    font-weight: 800;
    src: url("/fonts/FiraCode-Bold.woff") format("woff");
  }
  