
$color-accent-blue: #23B0FF;
$color-accent-orange: #ffa86a;
$color-accent-green: #78E2A0;
$color-accent-pink: #EE72F1;
$color-accent-red: #FF6266;

$color-base-background: #1D1E28;
$color-base-text: white;

$color-accent: $color-accent-orange;
$color-background: mix($color-accent, $color-base-background, 2%);
$color-border: rgba(255, 255, 255, .1);
$color-shadow: adjust-color($color-background, $alpha: -0.2);
$color-light-secondary: $color-base-text;

$shadow: 0 10px $color-shadow, -10px 10px $color-shadow, 10px 10px $color-shadow;



:root {
    /* variables for js, must be the same as these in @custom-media queries */
    --phoneWidth: (max-width: 684px);
    --tabletWidth: (max-width: 900px);
}

@custom-media --phone (width < 684px);
@custom-media --tablet (width < 900px);