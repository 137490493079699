.index-content {
    margin-top: 20px;
  }
  
  .framed {
    border: 1px solid $color-accent;
    padding: 20px;
  
    *:first-child {
      margin-top: 0;
    }
  
    *:last-child {
      margin-bottom: 0;
    }
  }
  
  .posts {
    width: 100%;

    .post.on-list:first-of-type {
      margin-top: 0px;
      padding-top: 0px;
    }
  }
  
  .post {
    width: 100%;
    text-align: left;
    margin: 20px auto;
    padding: 20px 0;
  
    @media (--tablet) {
      max-width: 660px;
    }
  
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-border;
    }
  
    &-meta {
      font-size: 1rem;
      margin-bottom: 10px;
      color: adjust-color($color-accent, $alpha: -0.3);
    }
  
    &-title {
      $border: 3px dotted $color-accent;
      position: relative;
      color: $color-accent;
      margin: 0 0 15px;
      padding-bottom: 15px;
      border-bottom: $border;
  
      &:after {
        content: '';
        position: absolute;
        bottom: 2px;
        display: block;
        width: 100%;
        border-bottom: $border;
      }
  
      a {
        text-decoration: none;
      }
    }
  
    &-tags {
      display: block;
      margin-bottom: 20px;
      font-size: 1rem;
      opacity: .5;
  
      a {
        text-decoration: none;
      }
    }
  
    &-content {
      margin-top: 30px;
    }
  
    &-cover {
      border: 20px solid $color-accent;
      background: transparent;
      margin: 40px 0;
      padding: 20px;
  
      @media (--phone) {
        padding: 10px;
        border-width: 10px;
      }
    }
  
    ul {
      list-style: none;
  
      li:not(:empty):before {
        content: '-';
        position: absolute;
        left: -20px;
        color: $color-accent;
      }
    }
  }
  
  .post--regulation {
    h1 {
      justify-content: center;
    }
  
    h2 {
      justify-content: center;
      margin-bottom: 10px;
  
      & + h2 {
        margin-top: -10px;
        margin-bottom: 20px;
      }
    }
  }
  
  .hanchor {
    color: adjust-color($color-accent, $alpha: -0.1);
    text-decoration: none;
    margin-left: 10px;
    visibility: hidden;
  }
  
  h1:hover a, h2:hover a, h3:hover a, h4:hover a {
    visibility: visible;
  }
  
  .footnotes {
    color: adjust-color($color-base-text, $alpha: -0.5);
  }
  