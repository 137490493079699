.footer {
    padding: 40px 0;
    flex-grow: 0;
    opacity: .5;
  
    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 760px;
      max-width: 100%;
  
      @media (--tablet) {
        flex-direction: column;
      }
    }
  
    a {
      color: inherit;
    }
  
    .copyright {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      color: $color-light-secondary;
  
      &--user {
        margin: auto;
        text-align: center;
      }
  
      & > *:first-child:not(:only-child) {
        margin-right: 10px;
  
         @media (--tablet) {
          border: none;
          padding: 0;
          margin: 0;
        }
      }
  
      @media (--tablet) {
        flex-direction: column;
        margin-top: 10px;
      }
    }
  }
  