code.language-css,
code.language-scss,
.token.boolean,
.token.string,
.token.entity,
.token.url,
.language-css .token.string,
.language-scss .token.string,
.style .token.string,
.token.attr-value,
.token.keyword,
.token.control,
.token.directive,
.token.statement,
.token.regex,
.token.atrule,
.token.number {
  color: $color-accent;
}

.token.tag-id,
.token.atrule-id,
.token.operator,
.token.unit,
.token.placeholder,
.token.variable,
.token.attr-name {
  color: adjust-color($color-accent, $alpha: -0.3);
}

.token.property,
.token.function,
code.language-javascript,
code.language-html,
.command-line-prompt > span:before {
  color: mix($color-accent, #999, 10%);
}

.token.selector,
.token.tag,
.token.punctuation {
  color: white;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: rgba(255, 255, 255, .3);
}

.token.namespace {
  opacity: .7;
}

pre[data-line] {
  position: relative;
}

.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  background: adjust-color(mix($color-accent, #999, 10%), $alpha: -0.92);
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
}

.line-highlight:before,
.line-highlight[data-end]:after {
  content: attr(data-start);
  position: absolute;
  /* top: .4em; */
  left: .6em;
  min-width: 1em;
  padding: 0 .5em;
  background-color: hsla(24, 20%, 50%, .4);
  color: hsl(24, 20%, 95%);
  font: bold 65%/1.5 sans-serif;
  text-align: center;
  vertical-align: .3em;
  border-radius: 999px;
  text-shadow: none;
  box-shadow: 0 1px white;
}

.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: .4em;
}

.line-numbers .line-highlight:before,
.line-numbers .line-highlight:after {
  content: none;
}
