.button-container {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  
  button,
  .button,
  a.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 18px;
    margin-bottom: 5px;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    border: 1px solid transparent;
    appearance: none;
    cursor: pointer;
    outline: none;
  
    /* variants */
  
    &.outline {
      background: transparent;
      box-shadow: none;
      padding: 8px 18px;
  
      :hover {
        transform: none;
        box-shadow: none;
      }
    }
  
    &.primary {
      box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  
      &:hover {
        box-shadow: 0 2px 6px rgba(50, 50, 93, .21), 0 1px 3px rgba(0, 0, 0, .08);
      }
    }
  
    &.link {
      background: none;
      font-size: 1rem;
    }
  
  
    /* sizes */
  
    &.small {
      font-size: .8rem;
    }
  
    &.wide {
      min-width: 200px;
      padding: 14px 24px;
    }
  }
  
  a.read-more,
  a.read-more:hover,
  a.read-more:active {
    display: inline-flex;
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 20px 0;
    max-width: 100%;
  }
  
  .code-toolbar {
    margin-bottom: 20px;
  
    .toolbar-item a {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 3px 8px;
      margin-bottom: 5px;
      text-decoration: none;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      border-radius: 8px;
      border: 1px solid transparent;
      appearance: none;
      cursor: pointer;
      outline: none;
    }
  }
  