html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Fira Code', Monaco, Consolas, Ubuntu Mono, monospace;
    font-size: 1rem;
    line-height: 1.54;
    letter-spacing: -0.02em;
    background-color: $color-background;
    color: $color-base-text;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga", "tnum", "case", "calt", "zero", "ss01", "locl";
    font-variant-ligatures: contextual;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
  
    @media (--phone) {
      font-size: 1rem;
    }
  }
  
  .headings--one-size {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.3;
  
      &:not(first-child) {
        margin-top: 40px;
      }
    }
  
    h1,
    h2,
    h3 {
      font-size: 1.4rem;
    }
  
    h4,
    h5,
    h6 {
      font-size: 1.2rem;
    }
  }
  
  a {
    color: inherit;
  
    /* Waiting for a better times... */
    /* &:has(code) {
      text-decoration-color: $color-accent;
    } */
  }
  
  img {
    display: block;
    max-width: 100%;
  
    &.left {
      margin-right: auto;
    }
  
    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  
    &.right {
      margin-left: auto;
    }
  }
  
  p {
    margin-bottom: 20px;
  }
  
  figure {
    display: table;
    max-width: 100%;
    margin: 25px 0;
  
    &.left {
      margin-right: auto;
    }
  
    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  
    &.right {
      margin-left: auto;
    }
  
    figcaption {
      font-size: 14px;
      padding: 5px 10px;
      margin-top: 5px;
      background: $color-accent;
      color: $color-background;
      /* opacity: .8; */
  
      &.left {
        text-align: left;
      }
  
      &.center {
        text-align: center;
      }
  
      &.right {
        text-align: right;
      }
    }
  }
  
  code, kbd {
    font-family: 'Fira Code', Monaco, Consolas, Ubuntu Mono, monospace;
    font-feature-settings: normal;
    background: adjust-color($color-accent, $alpha: -0.8);
    color: $color-accent;
    padding: 1px 6px;
    margin: 0 2px;
    font-size: .95rem;
  
    code, kbd {
      background: transparent;
      padding: 0;
      margin: 0;
    }
  }
  
  pre {
    background: transparent !important;
    padding: 20px 10px;
    margin: 40px 0;
    font-size: .95rem;
    overflow: auto;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
  
    + pre {
      border-top: 0;
      margin-top: -40px;
    }
  
    @media (--phone) {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  
    code {
      background: none !important;
      margin: 0;
      padding: 0;
      font-size: inherit;
      border: none;
    }
  }
  
  blockquote {
    border-top: 1px solid $color-accent;
    border-bottom: 1px solid $color-accent;
    margin: 40px 0;
    padding: 25px;
  
    @media (--phone) {
      padding-right: 0;
    }
  
    p:first-of-type {
      margin-top: 0;
    }
  
    p:last-of-type {
      margin-bottom: 0;
    }
  
    p {
      position: relative;
    }
  
    p:first-of-type:before {
      content: '>';
      display: block;
      position: absolute;
      left: -25px;
      color: $color-accent;
    }
  
    &.twitter-tweet {
      position: relative;
      background: adjust-color($color-accent, $alpha: -0.9);
      font: inherit;
      color: inherit;
      border: 1px solid $color-accent;
      padding-top: 60px;
  
      p:before {
        content: '';
      }
  
      &:before {
        content: '> From Twitter:';
        position: absolute;
        top: 20px;
        color: $color-accent;
        font-weight: bold;
      }
  
      a {
        color: $color-accent;
      }
    }
  }
  
  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin: 40px 0;
  }
  
  table,
  th,
  td {
    border: 1px dashed $color-accent;
    padding: 10px;
  }
  
  th {
    color: $color-accent;
  }
  
  ul,
  ol {
    margin-left: 30px;
    padding: 0;
  
    li {
      position: relative;
    }
  
    @media (--phone) {
      margin-left: 20px;
    }
  }
  
  ol {
    list-style: none;
    counter-reset: li;
  
    li {
      counter-increment: li;
    }
  
    li:before {
      content: counter(list-item);
      position: absolute;
      right: calc(100% + 10px);
      color: $color-accent;
      display: inline-block;
      text-align: right;
    }
  }
  
  ol ol {
    list-style-type: lower-alpha;
  }
  
  mark {
    background: $color-accent;
    color: $color-background;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 864px;
    min-height: 100vh;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  
    &.full,
    &.center {
      border: none;
      margin: 0 auto;
    }
  
    &.full {
      max-width: 100%;
    }
  
    @media (--phone) {
      padding: 20px;
    }
  
    @media print {
      display: initial;
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
  
    @media print {
      display: initial;
    }
  }
  
  hr {
    width: 100%;
    border: none;
    background: $color-border;
    height: 1px;
  }
  
  .hidden {
    display: none;
  }
  