@mixin menu {
    position: absolute;
    background: $color-background;
    box-shadow: $shadow;
    color: white;
    border: 2px solid;
    margin: 0;
    padding: 10px;
    list-style: none;
    z-index: 99;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    position: relative;
  
    @media print {
      display: none;
    }
  
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
    &__logo {
      display: flex;
      flex: 1;
  
      &:after {
        content: '';
        background: repeating-linear-gradient(45deg, $color-accent, $color-accent 2px, transparent 0, transparent 10px);
        background: linear-gradient(90deg, $color-accent 0%, $color-accent 35%, transparent 100%);
        display: block;
        width: 100%;
        right: 10px;
      }
  
      a {
        flex: 0 0 auto;
        max-width: 100%;
        text-decoration: none;
      }
    }
  
    .menu {
      $shadow: 0 10px $color-shadow, -10px 10px $color-shadow, 10px 10px $color-shadow;
      margin: 20px 0;
  
      @media (--phone) {
        @include menu;
        top: 50px;
        right: 0;
      }
  
      &__inner {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
  
        &--desktop {
          @media (--phone) {
            display: none;
          }
        }
  
        &--mobile {
          display: none;
  
          @media (--phone) {
            display: block;
          }
        }
  
        li {
          &:not(:last-of-type) {
            margin-right: 20px;
            margin-bottom: 10px;
            flex: 0 0 auto;
          }
        }
  
        @media (--phone) {
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
  
          li {
            margin: 0;
            padding: 5px;
          }
        }
      }
  
      &__sub-inner {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;
  
        &:not(:only-child) {
          margin-left: 20px;
        }
  
        &-more {
          @include menu;
          top: 35px;
          left: 0;
  
          &-trigger {
            color: $color-accent;
            user-select: none;
            cursor: pointer;
          }
  
          li {
            margin: 0;
            padding: 5px;
            white-space: nowrap;
          }
        }
      }
  
      .spacer {
        flex-grow: 1;
      }
  
      .language-selector {
        position: relative;
        list-style: none;
        margin: 0;
  
        &-current {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          color: $color-accent;
          cursor: pointer;
          margin: 0;
        }
  
        &__more {
          @include menu;
          top: 35px;
          right: 0;
        }
      }
  
      &-trigger {
        color: $color-accent;
        border: 2px solid;
        margin-left: 10px;
        height: 100%;
        padding: 3px 8px;
        position: relative;
      }
    }
  }
  